import React, { lazy, Suspense, useEffect } from "react"
import * as Sentry from "@sentry/react"
import LogRocket from "logrocket"
import { createRoot } from "react-dom/client"
import { useLocation } from "react-router-dom"
import { Provider as ReduxProvider } from "react-redux"
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import ReactPixel from "react-facebook-pixel"
import WalletContext from "./contexts/WalletContext"
import { FacebookProvider } from "./contexts/FacebookContext"
import { store } from "./store"
import lazyRetry from "./helpers/lazyRetry"
import "./css/global.scss"
import FacebookPixelTracker from "./helpers/FacebookPixelTracker"

// Dynamically load route components (for code splitting)
const AccountPage = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "account-page" */ "./Account/AccountPage")
  )
)
const ArtistPage = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "artist-page" */ "./Artist/ArtistPage")
  )
)
const CategoryPage = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "category-page" */ "./Events/CategoryPage")
  )
)
const CCTPPage = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "cctp-page" */ "./Account/CCTPPage")
  )
)
const CheckoutPage = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "checkout-page" */ "./Checkout/CheckoutPage")
  )
)
const Derby = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "derby" */ "./Derby/Derby"))
)
const EventList = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "event-list" */ "./Events/EventList")
  )
)
const EventPage = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "event-page" */ "./Events/EventPage")
  )
)
const HolderNFT = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "nft" */ "./Holder/NFT"))
)
const HolderNFTs = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "nfts" */ "./Holder/NFTs"))
)
const NoMatch = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "no-match" */ "./Home/NoMatch"))
)
const Redeem = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "redeem" */ "./Redeem/Redeem"))
)
const RewardPage = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "reward-page" */ "./Account/RewardPage")
  )
)
const SearchResults = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "search-results" */ "./Events/SearchResults")
  )
)
const SeatmapView = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "seat-map-view" */ "./components/SeatmapView/SeatmapView"
    )
  )
)
const UnwrappedSuccess = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "unwrapped-success" */ "./Unwrapped/UnwrappedSuccess"
    )
  )
)
const VelvetRope = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "new-velvet" */ "./Home/NewVelvet")
  )
)
const VenuePage = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "venue-page" */ "./Venue/VenuePage")
  )
)

var originalFetch = window.fetch
window.fetch = function (input, init) {
  if (
    input &&
    input.indexOf(process.env.REACT_APP_HNGR_API) === -1 &&
    input.indexOf(process.env.REACT_APP_EXPRESS_API) === -1
  ) {
    return originalFetch(input, init)
  }
  if (!init) {
    init = {}
  }
  if (!init.headers) {
    init.headers = new Headers()
  }

  if (process.env.REACT_APP_SKIN) {
    if (init.headers instanceof Headers) {
      init.headers.append("XP-SKIN", process.env.REACT_APP_SKIN)
    } else if (init.headers instanceof Array) {
      init.headers.push(["XP-SKIN", process.env.REACT_APP_SKIN])
    } else {
      init.headers["XP-SKIN"] = process.env.REACT_APP_SKIN
    }
  }

  return originalFetch(input, init)
}

const SENTRY_IGNORE_ERRORS = [
  "Failed to fetch",
  "XMLHttpRequest",
  "unknown",
  "extension",
]

if (process.env.REACT_APP_HNGR_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_HNGR_SENTRY_DSN,
    ignoreErrors: SENTRY_IGNORE_ERRORS,
    beforeSend(event, hint) {
      try {
        event.extra.LOGROCKET_SESSION_URL = window.LOGROCKET_SESSION_URL
        return event
      } catch (err) {
        return event
      }
    },
  })
}

if (process.env.REACT_APP_LOGROCKET) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET)
  LogRocket.getSessionURL((sessionURL) => {
    window.LOGROCKET_SESSION_URL = sessionURL
  })
}

const App = () => {
  let CheckoutComponent = CheckoutPage

  const advancedMatching = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  }

  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      window.prerenderReady = true
    }, 30000)
  }, [location])

  ReactPixel.init("1514233069351079", advancedMatching, options)

  return (
    <Routes>
      <Route path="/" element={<VelvetRope />} />

      <Route
        path="/mike"
        render={() => <Navigate to="/campaign/saunders3000" />}
      />

      <Route path="/campaign/:campaign_name" element={<VelvetRope />} />

      <Route path="/c/:campaign_name" element={<VelvetRope />} />

      <Route path="/holder/tickets" element={<HolderNFTs />} />

      <Route path="/holder/ticket/:mint" element={<HolderNFT />} />

      <Route path="/events" element={<EventList />} />

      <Route path="/events/cbsa/:cbsa" element={<EventList />} />

      <Route path="/events/search/:query" element={<SearchResults />} />

      <Route path="/events/city/:city/:state" element={<EventList />} />

      <Route path="/events/:category/:subcategory" element={<CategoryPage />} />

      <Route path="/event/:event_id" element={<EventPage />} />

      {/* seatmap webview for ios app */}
      <Route path="/seatmap/:event_id/:section_id" element={<SeatmapView />} />

      <Route
        path="/checkout/:event_id/:ticket_group_uvid"
        element={<CheckoutComponent />}
      />

      <Route
        path="/checkout/:event_id/:ticket_group_uvid/tickets"
        element={<CheckoutComponent />}
      />

      <Route
        path="/checkout/:event_id/:ticket_group_uvid/delivery"
        element={<CheckoutComponent />}
      />

      <Route
        path="/checkout/:event_id/:ticket_group_uvid/confirm-email"
        element={<CheckoutComponent />}
      />

      <Route
        path="/checkout/:event_id/:ticket_group_uvid/payment"
        element={<CheckoutComponent />}
      />

      <Route
        path="/checkout/:event_id/:ticket_group_uvid/success"
        element={<CheckoutComponent />}
      />

      <Route path="/artist/:artist_id/details" element={<ArtistPage />} />
      <Route path="/artist/:artist_id" element={<ArtistPage />} />

      <Route path="/venue/:venue_id" element={<VenuePage />} />

      <Route path="/account" element={<AccountPage />} />

      <Route path="/account/rewards" element={<RewardPage />} />

      <Route path="/account/cctp" element={<CCTPPage />} />

      <Route path="/redeem" element={<Redeem />} />

      <Route path="/g/derby" element={<Derby />} />

      <Route path="/g/derby/play" element={<Derby />} />

      <Route path="/g/unwrapped" element={<VelvetRope />} />
      <Route path="/g/unwrapped/success" element={<UnwrappedSuccess />} />
      <Route path="/g/finalplay" element={<VelvetRope />} />
      <Route path="/g/joebgiveaway" element={<VelvetRope />} />
      <Route path="/g/jancustomersperk" element={<VelvetRope />} />

      <Route path="*" element={<NoMatch />} />
    </Routes>
  )
}

const container = document.getElementById("root")
const root = createRoot(container)
root.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <FacebookProvider>
        <FacebookPixelTracker />
        <Suspense fallback={<div>Loading...</div>}>
          <WalletContext>
            <App />
          </WalletContext>
        </Suspense>
      </FacebookProvider>
    </BrowserRouter>
  </ReduxProvider>
)

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const initialState = {
  eventList: [],
  eventsLoaded: false,
  eventsSuccess: false,
  filteredEventList: [],
  filteredEventsLoaded: false,
  filteredEventsSuccess: false,
  eventsByLocation: {},
  eventsByLocationLoading: false,
  eventsByLocationSuccess: null,
  featuredEvents: [],
  featuredEventsLoaded: false,
  featuredEventsSuccess: false,
  nearbyFeaturedEvents: [],
  nearbyFeaturedEventsLoaded: false,
  nearbyFeaturedEventsSuccess: false,
  nearbyMusicEvents: [],
  nearbyMusicEventsLoaded: false,
  nearbyMusicEventsSuccess: false,
  nearbySportsEvents: [],
  nearbySportsEventsLoaded: false,
  nearbySportsEventsSuccess: false,
  nearbyEventsWithMaxPrice: [],
  nearbyEventsWithMaxPriceLoaded: false,
  nearbyEventsWithMaxPriceSuccess: false,
  originalLocation: "",
  recentlyAddedEvents: [],
  recentlyAddedEventsLoaded: false,
  recentlyAddedEventsSuccess: false,
  eventsByPerformer: [],
  eventsByPerformerLoading: false,
  selectedPerformer: null,
  selectedEvent: null,
  eventLoading: false,
  nearby: "",
  nearbyCBSA: "",
}

let headers = {
  "Content-Type": "application/json",
}

const getFilteredEventsFromApi = async (data) => {
  const endpoint = "/api/stagehand/filters"
  const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

  const response = await fetch(url, {
    method: "post",
    headers,
    body: JSON.stringify(data),
  }).then((jsonresponse) => {
    return jsonresponse.json()
  })

  return response
}

// I don't actually think this works, unclear
export const getAllEvents = createAsyncThunk(
  "events/getAllEvents",
  async (data, { rejectWithValue }) => {
    try {
      const events = await getFilteredEventsFromApi()
      return events
    } catch (err) {
      rejectWithValue(err)
    }
  }
)

let abortController = null

export const getFilteredEvents = createAsyncThunk(
  "events/getFilteredEvents",
  async (data, { rejectWithValue }) => {
    try {
      // Cancel the previous request if it exists
      if (abortController) {
        abortController.abort()
      }

      // Create a new abort controller for the current request
      abortController = new AbortController()

      const endpoint = "/api/stagehand/filters"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

      const response = await fetch(url, {
        method: "post",
        headers,
        body: JSON.stringify(data),
        signal: abortController.signal,
      }).then((jsonresponse) => {
        // Reset the abort controller since the request has completed successfully
        abortController = null

        return jsonresponse.json()
      })

      return response.events
    } catch (err) {
      if (err.name === "AbortError") {
        // The request was aborted, so you can handle it accordingly
        console.log("Request aborted")
      }
      rejectWithValue(err)
    }
  }
)

export const getEventById = createAsyncThunk(
  "events/getEventById",
  async (data, { rejectWithValue }) => {
    try {
      const endpoint = "/api/stagehand/event"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

      const response = await fetch(url, {
        method: "post",
        headers,
        body: JSON.stringify({ event_id: data }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      return response
    } catch (err) {
      rejectWithValue(err)
    }
  }
)

// get events by location - need count for home page and first three for recommendations
export const getEventsByLocation = createAsyncThunk(
  "events/getEventsByLocation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getFilteredEventsFromApi(data)
      return { events: response.events, location: data.cbsa }
    } catch (err) {
      rejectWithValue(err)
    }
  }
)

// get featured events - need three for homepage
export const getFeaturedEvents = createAsyncThunk(
  "events/getFeaturedEvents",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getFilteredEventsFromApi(data)
      return response.events
    } catch (err) {
      rejectWithValue(err)
    }
  }
)

// get featured events - need three for homepage
export const getNearbyFeaturedEvents = createAsyncThunk(
  "events/getNearbyFeaturedEvents",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getFilteredEventsFromApi(data)
      return response.events
    } catch (err) {
      rejectWithValue(err)
    }
  }
)

// get nearby music
export const getNearbyMusicEvents = createAsyncThunk(
  "events/getNearbyMusicEvents",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getFilteredEventsFromApi(data)
      return response
    } catch (err) {
      rejectWithValue(err)
    }
  }
)

// get nearby sports
export const getNearbySportsEvents = createAsyncThunk(
  "events/getNearbySportsEvents",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getFilteredEventsFromApi(data)
      return response.events
    } catch (err) {
      rejectWithValue(err)
    }
  }
)

// get nearby events under X dollars
export const getNearbyEventsWithMaxPrice = createAsyncThunk(
  "events/getNearbyEventsWithMaxPrice",
  async (data, { rejectWithValue }) => {
    try {
      const response = await getFilteredEventsFromApi(data)
      return response.events
    } catch (err) {
      rejectWithValue(err)
    }
  }
)

// get recently added events - need three for homepage
export const getRecentlyAddedEvents = createAsyncThunk(
  "events/getRecentlyAddedEvents",
  async (data, { rejectWithValue }) => {
    try {
      const endpoint = "/api/stagehand/recent-events"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

      const response = await fetch(url, {
        method: "get",
        headers,
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      return response.events
    } catch (err) {
      rejectWithValue(err)
    }
  }
)

// get events by artist
export const getEventsByPerformer = createAsyncThunk(
  "events/getEventsByPerformer",
  async (data, { rejectWithValue }) => {
    try {
      const endpoint = "/api/stagehand/events-by-performer"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

      const response = await fetch(url, {
        method: "post",
        headers,
        body: JSON.stringify({ performer_id: data }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      return response.events
    } catch (err) {
      rejectWithValue(err)
    }
  }
)

export const getPerfomerById = createAsyncThunk(
  "events/getPerformerById",
  async (data, { rejectWithValue }) => {
    try {
      const endpoint = "/api/stagehand/performer-by-id"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`
      const response = await fetch(url, {
        method: "post",
        headers,
        body: JSON.stringify({ performer_id: data }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      return response.performer
    } catch (err) {
      rejectWithValue(err)
    }
  }
)

export const getEventFromMint = createAsyncThunk(
  "events/getEventFromMint",
  async (mint, { rejectWithValue }) => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/event-from-mint`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      mint: mint,
    }

    try {
      const response = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      }).then((jsonresponse) => {
        const resp = jsonresponse.json()
        return resp
      })

      const endpoint = "/api/stagehand/event"
      const urlById = `${process.env.REACT_APP_HNGR_API}${endpoint}`

      const fullEventResponse = await fetch(urlById, {
        method: "post",
        headers,
        body: JSON.stringify({ event_id: response.event_id }),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      // combine event and ticket group info for use on the nft page
      return { ...response, ...fullEventResponse }
    } catch (err) {
      console.log(err, "error in get event")
      // alert("err")
      rejectWithValue(err)
    }
  }
)

export const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    resetEventsByPerformer: (state) => {
      state.eventsByPerformer = []
    },
    setSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload
    },
    clearSelectedEvent: (state) => {
      state.selectedEvent = null
    },
    clearSelectedPerformer: (state) => {
      state.selectedPerformer = null
    },
    updateLocation: (state, action) => {
      state.nearby = action.payload.nearby
      state.nearbyCBSA = action.payload.cbsa
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllEvents.fulfilled, (state, action) => {
      state.eventsLoaded = true
      state.eventsSuccess = true
      state.eventList = action.payload
    })

    builder.addCase(getAllEvents.rejected, (state, action) => {
      state.eventsLoaded = true
      state.eventsSuccess = false
    })

    builder.addCase(getFilteredEvents.fulfilled, (state, action) => {
      state.filteredEventsLoaded = true
      state.filteredEventsSuccess = true
      state.filteredEventList = action.payload
    })

    builder.addCase(getFilteredEvents.rejected, (state, action) => {
      state.filteredEventsLoaded = true
      state.filteredEventsSuccess = false
    })

    builder.addCase(getEventsByLocation.pending, (state) => {
      state.eventsByLocationLoading = true
    })

    builder.addCase(getEventsByLocation.fulfilled, (state, action) => {
      if (action.payload?.location) {
        state.eventsByLocationLoading = false
        state.eventsByLocationSuccess = true
        state.eventsByLocation = {
          ...state.eventsByLocation,
          [action.payload.location]: action.payload.events,
        }
      } else {
        state.eventsByLocationSuccess = false
      }
    })

    builder.addCase(getEventsByLocation.rejected, (state, action) => {
      state.eventsByLocationLoading = false
      state.eventsByLocationSuccess = false
    })

    builder.addCase(getFeaturedEvents.fulfilled, (state, action) => {
      state.featuredEventsLoaded = true
      state.featuredEventsSuccess = true
      state.featuredEvents = action.payload
    })

    builder.addCase(getFeaturedEvents.rejected, (state, action) => {
      state.featuredEventsLoaded = true
      state.featuredEventsSuccess = false
    })

    builder.addCase(getNearbyFeaturedEvents.fulfilled, (state, action) => {
      state.nearbyFeaturedEventsLoaded = true
      state.nearbyFeaturedEventsSuccess = true
      state.nearbyFeaturedEvents = action.payload
    })

    builder.addCase(getNearbyFeaturedEvents.rejected, (state, action) => {
      state.nearbyFeaturedEventsLoaded = true
      state.nearbyFeaturedEventsSuccess = false
    })

    builder.addCase(getNearbyMusicEvents.fulfilled, (state, action) => {
      state.nearbyMusicEventsLoaded = true
      state.nearbyMusicEventsSuccess = true
      state.nearbyMusicEvents = action.payload.events
      if (action.payload.nearby) {
        state.originalLocation = action.payload.nearby
        state.nearby = action.payload.nearby
      }
    })

    builder.addCase(getNearbyMusicEvents.rejected, (state, action) => {
      state.nearbyMusicEventsLoaded = true
      state.nearbyMusicEventsSuccess = false
    })

    builder.addCase(getNearbySportsEvents.fulfilled, (state, action) => {
      state.nearbySportsEventsLoaded = true
      state.nearbySportsEventsSuccess = true
      state.nearbySportsEvents = action.payload
    })

    builder.addCase(getNearbySportsEvents.rejected, (state, action) => {
      state.nearbySportsEventsLoaded = true
      state.nearbySportsEventsSuccess = false
    })

    builder.addCase(getNearbyEventsWithMaxPrice.pending, (state) => {
      state.nearbyEventsWithMaxPriceLoading = true
    })

    builder.addCase(getNearbyEventsWithMaxPrice.fulfilled, (state, action) => {
      state.nearbyEventsWithMaxPriceLoading = false
      state.nearbyEventsWithMaxPriceSuccess = true
      state.nearbyEventsWithMaxPrice = action.payload
    })

    builder.addCase(getNearbyEventsWithMaxPrice.rejected, (state, action) => {
      state.nearbyEventsWithMaxPriceLoading = false
      state.nearbyEventsWithMaxPriceSuccess = false
    })

    builder.addCase(getEventsByPerformer.pending, (state) => {
      state.eventsByPerformerLoading = true
    })

    builder.addCase(getEventsByPerformer.fulfilled, (state, action) => {
      state.eventsByPerformerLoading = false
      state.eventsByPerformerSuccess = true
      state.eventsByPerformer = action.payload
    })

    builder.addCase(getEventsByPerformer.rejected, (state, action) => {
      state.eventsByPerformerLoading = false
      state.eventsByPerformerSuccess = false
    })

    builder.addCase(getRecentlyAddedEvents.fulfilled, (state, action) => {
      state.recentlyAddedEventsLoaded = true
      state.recentlyAddedEventsSuccess = true
      state.recentlyAddedEvents = action.payload
    })

    builder.addCase(getRecentlyAddedEvents.rejected, (state, action) => {
      state.recentlyAddedEventsLoaded = true
      state.recentlyAddedEventsSuccess = false
    })

    builder.addCase(getEventById.pending, (state) => {
      state.eventLoading = true
    })

    builder.addCase(getEventById.fulfilled, (state, action) => {
      state.eventLoading = false
      state.eventSuccess = true
      state.selectedEvent = action.payload
    })

    builder.addCase(getEventById.rejected, (state, action) => {
      state.eventLoading = false
      state.eventSuccess = false
    })

    builder.addCase(getPerfomerById.pending, (state) => {
      state.performerLoading = true
    })

    builder.addCase(getPerfomerById.fulfilled, (state, action) => {
      state.performerLoading = false
      state.performerSuccess = true
      state.selectedPerformer = action.payload
    })

    builder.addCase(getPerfomerById.rejected, (state, action) => {
      state.performerLoading = false
      state.performerSuccess = false
    })
    builder.addCase(getEventFromMint.pending, (state) => {
      state.eventLoading = true
    })
    builder.addCase(getEventFromMint.fulfilled, (state, action) => {
      state.eventLoading = false
      state.eventSuccess = true
      state.selectedEvent = action.payload
    })
    builder.addCase(getEventFromMint.rejected, (state, action) => {
      state.eventLoading = false
      state.eventSuccess = false
    })
  },
})

export const {
  setSelectedEvent,
  clearSelectedEvent,
  clearSelectedPerformer,
  resetEventsByPerformer,
  updateLocation,
} = eventSlice.actions

export default eventSlice.reducer

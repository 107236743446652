import React, { createContext, useContext, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"

const FacebookContext = createContext()

export const FacebookProvider = ({ children }) => {
  const location = useLocation()
  const [eventId, setEventId] = useState(generateEventId())

  useEffect(() => {
    // Generate a new event_id on every location change
    setEventId(generateEventId())
  }, [location])

  return (
    <FacebookContext.Provider value={{ eventId, setEventId }}>
      {children}
    </FacebookContext.Provider>
  )
}

export const useEvent = () => useContext(FacebookContext)

const generateEventId = () =>
  `event_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`

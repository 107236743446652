import { useEffect } from "react"
import ReactPixel from "react-facebook-pixel"
import { useEvent } from "../contexts/FacebookContext"

const FacebookPixelTracker = () => {
  const { eventId } = useEvent()

  useEffect(() => {
    ReactPixel.fbq("track", "PageView", {}, { event_id: eventId })
  }, [eventId]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default FacebookPixelTracker

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const initialState = {
  selectedTicketGroup: null,
  selectedTicketGroupLoading: false,
  selectedTicketGroupError: false,
}

let headers = {
  "Content-Type": "application/json",
}

// placeholder - this endpoint doesn't exist yet
export const getTicketGroupById = createAsyncThunk(
  "tickets/getTicketGroupById",
  async (data, { rejectWithValue }) => {
    try {
      const endpoint = "/api/stagehand/live-ticketgroup-by-id"
      const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`
      const response = await fetch(url, {
        method: "post",
        headers,
        body: JSON.stringify(data),
      }).then((jsonresponse) => {
        return jsonresponse.json()
      })
      return response.ticket_group
    } catch (err) {
      rejectWithValue(err)
    }
  }
)

export const ticketSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setSelectedTicketGroup: (state, action) => {
      state.selectedTicketGroup = action.payload
    },
    clearSelectedTicketGroup: (state) => {
      state.selectedTicketGroup = null
    },
    clearTicketGroups: (state) => {
      state.ticketGroups = []
    },
    setSelectedTicketThumb: (state, action) => {
      state.selectedTicketThumb = action.payload
    },
    clearSelectedTicketThumb: (state) => {
      state.selectedTicketThumb = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTicketGroupById.pending, (state, action) => {
      state.selectedTicketGroupLoading = true
    })
    builder.addCase(getTicketGroupById.fulfilled, (state, action) => {
      state.selectedTicketGroupLoading = false
      state.selectedTicketGroup = action.payload
    })
    builder.addCase(getTicketGroupById.rejected, (state, action) => {
      state.selectedTicketGroupLoading = false
      state.selectedTicketGroupError = true
    })
  },
})

export const {
  setSelectedTicketGroup,
  clearSelectedTicketGroup,
  setSelectedTicketThumb,
  clearSelectedTicketThumb,
} = ticketSlice.actions

export default ticketSlice.reducer

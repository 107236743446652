import { getCoupons, getTokenAccountsForUser } from "./getTokenAccounts"

const getJWT = async (u) => {
  const url = `${process.env.REACT_APP_HNGR_API}/api/xp/iterable/v3`

  let body = {
    uuid: u.uuid,
  }

  let resp = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
  let json = await resp.json()
  return json.jwt
}

const getHeaders = async (u) => {
  if (!window.JWT) {
    window.JWT = await getJWT(u)
  }
  let headers = {
    "Content-Type": "application/json",
    "Api-Key": process.env.REACT_APP_ITERABLE_API_KEY_JWT,
    Authorization: `Bearer ${window.JWT}`,
  }
  return headers
}

export const recordIterableUser = async (u, adapter) => {
  if (!u.email) return
  let url = `https://api.iterable.com/api/users/update`

  let headers = await getHeaders(u)

  const { decryptList, decryptListPast } = await getTokenAccountsForUser(
    adapter,
    u
  )

  const allTickets = [...decryptList, ...decryptListPast]

  const ticketList = []
  allTickets?.forEach((t) => {
    let t2 = JSON.parse(JSON.stringify(t.metadata._json))
    if (t2.date_local) {
      delete t2["date_local"]
    }
    if (t2.date_utc) {
      delete t2["date_utc"]
    }
    ticketList.push({
      ...t2,
    })
  })

  const rewards = await getCoupons(u)

  const rewardList = []
  rewards?.forEach((r) => {
    rewardList.push({
      mint: r.mint.address,
      ...r.metadata.json,
      discount:
        r.metadata.json.discount_type === "flat"
          ? r.metadata.json.discount / 100
          : r.metadata.json.discount,
      minimum: r.metadata.json.minimum / 100,
      fragmentId: r.fragment_id,
    })
  })

  const initialCampaign =
    window.INITIAL_CAMPAIGN || localStorage.getItem("campaign_name")

  if (!u.email) return

  let body = {
    email: u.email,
    dataFields: {
      userId: u?.uuid?.toString(),
      walletAddress: u.publicKey,
      tickets: ticketList,
      promos: rewardList,
      lastName: u.last_name,
      firstName: u.first_name,
      phoneNumber: u.phone,
      initialCampaign,
      initialWalletType: u.loginMethod,
      ipInferredCity: u.ipInferredCity,
      ipInferredMetro: u.ipInferredMetro,
      ip_geo_location_geo_location: u.ip_geo_location_geo_location,
    },
    preferUserId: true,
    mergeNestedObjects: true,
  }

  try {
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(body),
    })
    await resp.json()
  } catch (err) {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/iterable/merge-users`
    let body = {
      sourceEmail: u.email,
      destinationUserId: u?.uuid?.toString(),
    }
    await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
    console.log("Error recording user", err)
  }
}

export const recordIterableLogin = async (u) => {
  if (!u.email) return

  let url = `https://api.iterable.com/api/events/track`

  let headers = await getHeaders(u)

  const now = new Date()

  try {
    await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify({
        email: u.email,
        eventName: "login",
        createdAt: Date.now(),
        dataFields: {
          userId: u?.uuid?.toString(),
          email: u.email,
          lastSeen: now.toISOString(),
        },
      }),
    })
  } catch (err) {
    console.log("Error recording login", err)
  }
}

export const recordIterableSignup = async (u) => {
  if (!u.email) return
  let url = `https://api.iterable.com/api/events/track`

  let headers = await getHeaders(u)

  const now = new Date()

  try {
    await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify({
        email: u.email,
        eventName: "signedUp",
        createdAt: Date.now(),
        dataFields: {
          userId: u?.uuid?.toString(),
          campaignRoute: u.initialCampaign,
          creationDate: now.toISOString(),
          dispenser: u.initialDispenser,
          initialWalletType: u.loginMethod,
          referralSource: u.signupSource,
          email: u.email,
          wallet: u.wallet,
        },
      }),
    })
  } catch (err) {
    console.log("Error recording login", err)
  }
}

export const recordIterablePageview = async (u, route, pageTitle, data) => {
  if (!u.email) return
  let url = `https://api.iterable.com/api/events/track`
  let headers = {
    "Content-Type": "application/json",
    "Api-Key": process.env.REACT_APP_ITERABLE_API_KEY,
  }

  try {
    await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify({
        email: u.email,
        eventName: "pageView",
        createdAt: Date.now(),
        dataFields: {
          userId: u?.uuid?.toString(),
          route,
          pageTitle,
          email: u.email,
          ...data,
        },
      }),
    })
  } catch (err) {
    console.log("Error recording login", err)
  }
}

export const recordIterableDeliveryDetails = async (u, data) => {
  if (!u.email) return
  let url = `https://api.iterable.com/api/events/track`

  let headers = await getHeaders(u)

  try {
    await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify({
        email: u.email,
        eventName: "deliveryDetails",
        createdAt: Date.now(),
        dataFields: {
          userId: u?.uuid?.toString(),
          email: u.email,
          ...data,
        },
      }),
    })
  } catch (err) {
    console.log("Error recording login", err)
  }
}

export const recordIterableCheckoutStarted = async (u, data) => {
  if (!u.email) return
  let url = `https://api.iterable.com/api/events/track`

  let headers = await getHeaders(u)

  try {
    await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify({
        email: u.email,
        eventName: "checkoutStarted",
        createdAt: Date.now(),
        dataFields: {
          userId: u?.uuid?.toString(),
          email: u.email,
          ...data,
        },
      }),
    })
  } catch (err) {
    console.log("Error recording login", err)
  }
}

export const recordIterablePromoEntered = async (u, data) => {
  if (!u.email) return
  let url = `https://api.iterable.com/api/events/track`

  let headers = await getHeaders(u)

  try {
    await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify({
        email: u.email,
        eventName: "promoEntered",
        createdAt: Date.now(),
        createNewFields: false,
        dataFields: {
          userId: u?.uuid?.toString(),
          email: u.email,
          details: {
            ...data,
          },
        },
      }),
    })
  } catch (err) {
    console.log("Error recording login", err)
  }
}

export const recordIterablePromoSelected = async (u, data) => {
  if (!u.email) return
  let url = `https://api.iterable.com/api/events/track`

  let headers = await getHeaders(u)

  try {
    await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify({
        email: u.email,
        eventName: "promoSelected",
        createdAt: Date.now(),
        createNewFields: false,
        dataFields: {
          userId: u?.uuid?.toString(),
          email: u.email,
          details: {
            ...data,
          },
        },
      }),
    })
  } catch (err) {
    console.log("Error recording login", err)
  }
}

export const recordIterablePurchase = async (
  u,
  purchaseId,
  event,
  ticketGroup,
  data
) => {
  if (!u.email) return
  let url = `https://api.iterable.com/api/commerce/trackPurchase`

  let headers = await getHeaders(u)

  let categories = []
  if (event.event_type) {
    categories.push(event.event_type)
  }
  if (event.category) {
    categories.push(event.category)
  }
  if (event.genre) {
    categories.push(event.genre)
  }
  if (event.league) {
    categories.push(event.league)
  }

  try {
    await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify({
        id: purchaseId,
        email: u.email,
        user: { email: u.email },
        items: [
          {
            id: event.event_id.toString(),
            sku: ticketGroup.id,
            name: event.title,
            categories,
            price: ticketGroup.price / 100,
            quantity: parseInt(data.quantity),
            imageUrl: event.image,
            dataFields: {
              section: ticketGroup.section.name,
              row: ticketGroup.row.name,
              in_hand_date: ticketGroup.in_hand_date,
              date_local: event.date_local,
              date_local_formatted: event.date_local.replace("T", " "),
              venue_name: event.venue_name,
              venue: event.venue,
              venue_city: event.venue_city,
              venue_state: event.venue_state,
            },
          },
        ],
        campaignId: 0,
        templateId: 0,
        total: data.total,
        createdAt: Date.now(),
        dataFields: {
          userId: u?.uuid?.toString(),
          currency: data.currency,
          promo: data.promo,
          fragment_id: data.fragment_id,
        },
      }),
    })
  } catch (err) {
    console.log("Error recording login", err)
  }
}
